import { Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import LazyLoad from "react-lazyload";
import { SafeHtmlParser } from "./SafeHtmlParser";

const TwoColumn = ({
  icon,
  heading,
  content,
  button,
  image,
  videoUrl,
  mediaType,
  flip = false,
}) => {
  const [showPlayBtn, setShowPlayBtn] = useState(true);

  const vidRef = useRef(null);
  const handlePlayVideo = () => {
    vidRef.current.play();
    setShowPlayBtn(!showPlayBtn);
  };

  return (
    <section className={`py-6 py-lg-8 two-column-wrapper`}>
      <Container>
        <Row
          className={`align-items-lg-center justify-content-between flex-column-reverse ${
            flip ? "flex-lg-row-reverse" : "flex-lg-row"
          }`}
        >
          <Col
            lg={6}
            className={`mb-5 mb-lg-0 text-start ${
              flip ? "ps-lg-4 ps-xl-8" : "pe-lg-4 pe-xl-8"
            }`}
          >
            <GatsbyImage
              image={icon}
              alt={heading}
              style={{ maxWidth: "124px" }}
              className="object-fit-contain"
            />
            <h2 className="my-0 py-4 text-primary">{heading}</h2>

            {content && (
              <div className="default-content">
                <SafeHtmlParser htmlContent={content} />
              </div>
            )}

            {button && button.url && (
              <Button
                href={button.url}
                size="lg"
                className="ubuntu-normal fs-6 mt-2"
                target={button?.target ?? "_blank"}
                rel="noreferrer"
                variant="primary"
              >
                {button.title}
              </Button>
            )}
          </Col>
          <Col
            xs={{ span: 12, order: "first" }}
            lg={{ span: 6, order: "last" }}
            className={`text-start ${
              flip ? "pe-lg-4" : "ps-lg-4"
            } position-relative`}
          >
            {mediaType === "Image" && image ? (
              <div className=" d-flex justify-content-lg-end">
                <GatsbyImage
                  image={image}
                  alt={image.altText}
                  className="rounded-5 object-fit-cover w-xl-70"
                />
              </div>
            ) : (
              <>
                {videoUrl && (
                  <LazyLoad height={200} offset={100}>
                    <div>
                      <video
                        ref={vidRef}
                        width="100%"
                        controls
                        className="object-fit-contain rounded-4"
                        style={{ maxHeight: "440px" }}
                      >
                        <source src={videoUrl} type="video/mp4" />
                      </video>

                      {showPlayBtn && (
                        <button
                          onClick={handlePlayVideo}
                          className="position-absolute m-auto start-0 end-0 top-0 bottom-0 border-0 rounded-circle"
                          style={{
                            width: "100px",
                            height: "100px",
                            backgroundColor: "transparent",
                          }}
                        >
                          <StaticImage
                            src="../images/solid-play.svg"
                            className="w-100 h-100 object-fit-contain"
                            imgStyle={{ objectFit: "contain" }}
                          />
                        </button>
                      )}
                    </div>
                  </LazyLoad>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default TwoColumn;
