import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { BgImage } from "gbimage-bridge";
import { Button } from "react-bootstrap";
import { SafeHtmlParser } from "./SafeHtmlParser";

const SingleArticle = ({ heading, content, backgorundImage, icon, button }) => {
  return (
    <section>
      <BgImage className="my-6 my-md-0 mx-4 mx-md-0 article-bg" image={backgorundImage}>
        <Container>
          <Row className="justify-content-center py-5 py-lg-7">
            <Col lg={8}>
              <div
                style={{ borderRadius: "32px" }}
                className="h-100 w-100 text-center"
              >
                {icon && (
                  <div className="text-center mb-4 pb-2">
                    <GatsbyImage className="w-40 w-md-20" image={icon} alt={heading} />
                  </div>
                )}

                {heading && (
                  <h2 className=" text-white pb-3 pb-md-4 mt-4">{heading}</h2>
                )}

                {content && (
                  <div className="text-white">
                    <SafeHtmlParser htmlContent={content} />
                  </div>
                )}

                {button && button.url && (
                  <Button
                    href={button.url}
                    size="lg"
                    className="ubuntu-normal fs-6 mt-2 mt-md-3 text-primary"
                    target="_blank"
                    rel="noreferrer"
                    variant="white"
                  >
                    {button.title}
                  </Button>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </BgImage>
    </section>
  );
};

export default SingleArticle;
