import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Marquee from "react-fast-marquee";

const FeaturedIn = ({ heading, logos }) => {
  return (
    <section className="py-5 py-md-6 py-xl-7 bg-dark-bg">
      <Container>
        <Row className="align-items-lg-center">
          <Col lg={10} className="text-start">
            <h2 className="text-uppercase my-auto text-white pb-2">
              {heading}
            </h2>
          </Col>
        </Row>
      </Container>
      <div className="mt-4 mt-md-5 pl-72">
        {logos && logos.length > 0 && (
          <Marquee>
            {logos.map((item, i) => (
              <GatsbyImage
                image={
                  item.FeaturedLogosFields?.logo?.localFile.childImageSharp
                    .gatsbyImageData
                }
                style={{
                  maxWidth: "132px",
                  maxHeight: "60px",
                }}
                imgStyle={{ objectFit: "contain" }}
                alt={item.FeaturedLogosFields?.logo?.altText}
                key={i}
                className="me-5 me-md-8"
              />
            ))}
          </Marquee>
        )}
      </div>
    </section>
  );
};

export default FeaturedIn;
