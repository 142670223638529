import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const PodCastList = ({ heading, items }) => {
  return (
    <section className="py-6 py-md-7">
      <Container>
        <Row className="align-items-lg-center">
          <Col lg={12} className="text-center">
            <h2 className="text-uppercase my-auto text-dark-bg pb-2">
              {heading}
            </h2>
          </Col>
        </Row>

        <div className="mt-5">
          {items && items.length > 0 && (
            <div>
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={{
                  desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3,
                  },
                  tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2,
                  },
                  mobile: {
                    breakpoint: { max: 464, min: 320 },
                    items: 1,
                  },
                
                }}
                infinite={false}
                arrows={false}
                className="pb-6"
              >
                {items.slice(0, 10).map((item, i) => (
                   <div className="px-2">
                   <GatsbyImage
                     image={
                       item.podcastFields?.image?.localFile.childImageSharp
                         .gatsbyImageData
                     }
                     imgStyle={{ objectFit: "contain" }}
                     alt={item.podcastFields?.image?.altText}
                     key={i}
                     className="rounded-4"
                   />
                   <h6 className="fs-6 text-primary mt-3">{item.title}</h6>
                   {item.podcastFields.link && item.podcastFields.link.url && (
                     <Button
                       href={item?.podcastFields.link.url}
                       size="lg"
                       className="px-0 ubuntu-normal fs-6 black-link"
                       target="_blank"
                       rel="noreferrer"
                       variant="transparent"
                     >
                       {item?.podcastFields.link.title}{" "}
                       <StaticImage
                         src="../images/chevron-right.png"
                         style={{ width: "24px" }}
                       />
                     </Button>
                   )}
                 </div>
                ))}
              </Carousel>

            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export default PodCastList;
