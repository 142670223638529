import { useStaticQuery, graphql } from "gatsby";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/layout";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Hero from "../components/hero";
import TeamImageSection from "../components/team-image-section";
import FeaturedIn from "../components/FeaturedIn";
import TwoColumn from "../components/TwoColumn";
import ReviewCardProf from "../components/review-card-prof";
import AboutInfo from "../components/AboutInfo";
import SingleArticle from "../components/SingleArticle";
import PodCastList from "../components/PodCastList";

const InTheNews = ({ data }) => {
  const siteUrl = data.site.siteMetadata.siteUrl;

  const allWpPodcastList = data?.allWpPodcast.nodes;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "About Us",
        item: {
          url: `${siteUrl}/about-us`,
          id: `${siteUrl}/about-us`,
        },
      },
    ],
  };

  const componentMap = {
    Page_Pagefieldsgroup_Components_ArticleListing: (data) => (
      <>
        {data.article && data.article.length > 0 && (
          <>
            {data.article.map((item, i) => (
              <TwoColumn
                icon={item?.logo?.localFile?.childImageSharp?.gatsbyImageData}
                heading={item.title}
                content={item.content}
                mediaType={item.mediaType}
                button={item.link}
                videoUrl={item?.video?.mediaItemUrl}
                image={item.image?.localFile?.childImageSharp?.gatsbyImageData}
                flip={i % 2 !== 0}
              />
            ))}
          </>
        )}
      </>
    ),
    Page_Pagefieldsgroup_Components_AboutInfo: (data) => (
      <AboutInfo
        heading={data?.heading}
        content={data?.content}
        backgroundColor={data?.backgroundColor}
        image={data?.image?.localFile?.childImageSharp?.gatsbyImageData}
        button={data?.link}
      />
    ),
    Page_Pagefieldsgroup_Components_SingleArticle: (data) => (
      <SingleArticle
        heading={data?.heading}
        content={data?.content}
        button={data?.link}
        backgorundImage={
          data?.backgorundImage?.localFile?.childImageSharp?.gatsbyImageData
        }
        icon={data?.icon?.localFile?.childImageSharp?.gatsbyImageData}
      />
    ),
    Page_Pagefieldsgroup_Components_PodacastListing: (data) => (
      <PodCastList heading={data?.heading} items={allWpPodcastList} />
    ),
  };

  return (
    <>
      <Helmet>
        {" "}
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>
      {/* <LocalBusinessSchema /> */}
      <GatsbySeo
        title="In The News | The Facial Rehabilitation Centre"
        description=""
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/about-us`,
          title: "In The News | The Facial Rehabilitation Centre",
          description: "",
          images: [
            {
              url: `${data.heroImg?.localFile.publicURL}`,
              width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
              height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
              alt: `${data.heroImg?.altText}`,
            },
          ],
        }}
      />

      <Layout>
        <section>
          <Hero
            subTitle="Home > In the News"
            title="Our brand in the media"
            backgroundImage={
              data.heroImg?.localFile.childImageSharp.gatsbyImageData
            }
            isBottomMargin={false}
          />
        </section>

        <FeaturedIn
          heading="As Featured in"
          logos={data.allWpFeaturedLogo?.nodes}
        />
        {data.wpPage.pageFieldsGroup.components.map((component, index) => {
          const renderComponent = componentMap[component.fieldGroupName];
          return renderComponent ? (
            <div key={index}>{renderComponent(component)}</div>
          ) : null;
        })}
        <section className="py-5 py-lg-7">
          <Container>
            <Row>
              <Col className="text-center pb-3 pb-lg-7">
                <h2 className="text-dark-bg pb-4">Professional testimonials</h2>
                {/* <p>
									Duis aute irure dolor in reprehenderit in voluptate velit esse
									cillum dolore fugiat sunt culpa officia deserunt mollit anim
									est laborum.
								</p> */}
              </Col>
            </Row>
            <Row className="gx-5 pt-5">
              {data.profReviews.nodes
                .filter((item, index) => index < 4)
                .map((review) => (
                  <Col className="mb-5 mb-xl-0" xs={12} md={6} xl={3}>
                    <ReviewCardProf review={review} />
                  </Col>
                ))}
            </Row>
          </Container>
        </section>
        <FeaturedIn
          heading="As Featured in"
          logos={data.allWpFeaturedLogo?.nodes}
        />
      </Layout>
    </>
  );
};

export default InTheNews;

export const pageQuery = graphql`
  query HomeById {
    site {
      siteMetadata {
        siteUrl
      }
    }
    heroImg: wpMediaItem(title: { eq: "About-Us-hero-image" }) {
      altText
      localFile {
        publicURL
        childImageSharp {
          original {
            height
            width
          }
          gatsbyImageData(
            formats: [AUTO, WEBP]
            quality: 100
            transformOptions: { cropFocus: CENTER, fit: CONTAIN }
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    img1: wpMediaItem(title: { eq: "About-Us-image-1" }) {
      altText
      localFile {
        publicURL
        childImageSharp {
          original {
            height
            width
          }
          gatsbyImageData(
            formats: [AUTO, WEBP]
            quality: 100
            transformOptions: { cropFocus: CENTER, fit: CONTAIN }
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    allWpFeaturedLogo {
      nodes {
        FeaturedLogosFields {
          logo {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    profReviews: allWpTestimonial(
      filter: { testimonialFeilds: { professional: { eq: true } } }
    ) {
      nodes {
        testimonialFeilds {
          name
          professional
          review
          jobTitle
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
            altText
          }
        }
      }
    }
    allWpPodcast(sort: {order: ASC, fields: date}) {
      nodes {
        title
        podcastFields {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                  layout: CONSTRAINED
                  placeholder: BLURRED
                )
              }
            }
          }
          link {
            target
            title
            url
          }
        }
      }
    }
    wpPage(slug: { eq: "in-the-news" }) {
      id
      pageFieldsGroup {
        components {
          ... on WpPage_Pagefieldsgroup_Components_ArticleListing {
            fieldGroupName
            article {
              content
              mediaType
              title
              logo {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
              link {
                target
                title
                url
              }
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      formats: [AUTO, WEBP]
                      quality: 100
                      transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
              video {
                mediaItemUrl
              }
            }
          }
          ... on WpPage_Pagefieldsgroup_Components_AboutInfo {
            backgroundColor
            content
            fieldGroupName
            heading
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            link {
              target
              title
              url
            }
          }
          ... on WpPage_Pagefieldsgroup_Components_SingleArticle {
            content
            fieldGroupName
            backgorundImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            heading
            icon {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                }
              }
            }
            link {
              target
              title
              url
            }
          }
          ... on WpPage_Pagefieldsgroup_Components_PodacastListing {
            fieldGroupName
            heading
          }
        }
      }
    }
  }
`;
