import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";
import { SafeHtmlParser } from "./SafeHtmlParser";

const AboutInfo = ({ heading, content, backgroundColor, image, button }) => {
  return (
    <section style={{ backgroundColor: backgroundColor }}>
      <Container>
        <Row className="justify-content-start py-6 py-lg-7">
          <Col md={4} className="pr-lg-4 pe-xl-7">
              <GatsbyImage
                image={image}
                alt={heading}
                className="about-image w-100"
              />
          </Col>
          <Col md={8} className="ps-lg-5 mt-3 mt-md-0">
            {heading && <h2 className="text-white">{heading}</h2>}

            {content && (
              <div
                className="text-white mt-3 mt-md-4">
                <SafeHtmlParser htmlContent={content} />
              </div>

            )}

            {button && button.url && (
              <Button
                href={button.url}
                size="lg"
                className="ubuntu-normal fs-6 mt-2 mt-md-4 text-primary"
                target={button?.target ?? "_blank"}
                rel="noreferrer"
                variant="white"
              >
                {button.title}
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default AboutInfo;
